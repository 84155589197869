/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "./components";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";
import { ReactComponent as StravaLogo } from "../images/pwrdBy_strava_logo_light.svg";

const DashboardPage = () => {
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const { isLoggedOut, runner } = useAuth();
  const { error, data } = T.useDashboardPageQuery({
    variables: {},
    skip: !runner,
  });
  console.table({ data, error })
  if (isLoggedOut) return <UI.Reload href="/" />;
  if (error) return <UI.Error error={error} />;
  if (!data || !runner || !data.runner) return <UI.Loading />;

  return (
    <UI.Container sx={{ pb: 3, pt: 2 }}>
      <UI.Seo title="Dashboard" url="/v3" />
      <UI.Grid container columnSpacing={3} sx={{ mt: 1 }}>
        <UI.Grid item xs={12} md={5} lg={4}>
          <C.RunnerInfo runner={data.runner} sx={{ mb: 4 }} />

          {data.runner.ctas.map((cta) => (
            <UI.Cta
              key={cta.id}
              cta={cta}
              sx={{ mb: 2 }}
              runnerRoles={data.runner?.roles}
            />
          ))}
          <C.UpcomingSessions
            title="Your upcoming sessions"
            sessions={data.runner.upcomingSessions}
            sx={{ mt: 4 }}
            md={md ? true : false}
          />
          <C.UpcomingSessions
            title="Upcoming sessions in your areas"
            sessions={data.runner.suggestedSessions}
            sx={{ mt: 4 }}
            md={md ? true : false}
          />
          <UI.Button
            variant="text"
            color="secondary"
            href={u.links.sessions()}
            sx={{ ml: -1, mb: 5 }}
          >
            View all upcoming sessions
          </UI.Button>
          <UI.Divider />
          <UI.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StravaLogo style={{ height: "60px", width: "auto" }} />
          </UI.Box>
        </UI.Grid>

        <UI.Grid item xs={12} md={7} lg={8}>
          <UI.Newsfeed type="Team" />
        </UI.Grid>
      </UI.Grid>
    </UI.Container>
  );
};

export default DashboardPage;
