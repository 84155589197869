/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as NP from "@goodgym/noun-project";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "@goodgym/auth";
import { useDeleteGroupFitnessActivity } from "@goodgym/graphql/mutations";


export type GettingThereProps = UI.BoxProps & {
  session: T.SessionPageQuery["session"];
};

const GettingThere: React.FC<React.PropsWithChildren<GettingThereProps>> = ({
  session,
  ...props
}) => {
  const { runner } = useAuth();
  const [params, setParams] = useSearchParams();
  const deleteGroupFitnessActivity = useDeleteGroupFitnessActivity();
  const activityIcons = {
    Run: <Icons.DirectionsRun fontSize="small" />,
    Walk: <Icons.DirectionsWalk fontSize="small" />,
    Cycle: <Icons.DirectionsBike fontSize="small" />
  };
  const showSignupModal = (gfa?: T.GroupFitnessActivityFragment) =>
    gfa
      ? setParams({ ...params, "session-signup": "true", "gfa-id": gfa.id })
      : setParams({ ...params, "session-signup": "true", "gfa-id": "NONE" });

  if (!u.session.isCommunityMission(session)) return null;

  const signedUpToGfa = !!session.groupFitnessActivities.find((gfa) => {
    return !!gfa.signups.find((s) => s.id === runner?.id);
  });


  return (
    <UI.Box {...props}>

      <UI.Typography variant="h5" color="primary.main" sx={{ mt: 5, mb: 2 }}>
        Getting there
      </UI.Typography>
      {session.groupFitnessActivities.length === 0 && (
        <UI.Box>
          <UI.Typography sx={{ mb: 2 }}>
            For this session we're going to meet at the task, so you'll need to
            make your own way there. We encourage you to walk, run, or cycle to
            do so!
          </UI.Typography>

          {(!session.registerMax ||
            session.registerMax > session.signups.length) && (
            <UI.List>
              {u.roles.hasSessionAdmin(runner, session) && !signedUpToGfa && (
                <UI.ListItem disableGutters>
                  <UI.ListItemIcon sx={{ mr: 2 }}>
                    <UI.Avatar style={{ fontSize: "28px" }}>
                      <Icons.Person />
                    </UI.Avatar>
                  </UI.ListItemIcon>
                  <UI.ListItemText
                    primary={
                      <UI.Link
                        sx={{ color: "black.main" }}
                        href={u.links.newGroupFitnessActivity(session)}
                      >
                        Invite others to run, walk, or cycle with you



                      </UI.Link>
                    }
                    secondary="Let people know where and when to meet you"
                  />
                  <UI.ListItemIcon sx={{ ml: 2 }}>
                    <UI.IconButton
                      href={u.links.newGroupFitnessActivity(session)}
                    >
                      <Icons.ArrowForward />
                    </UI.IconButton>
                  </UI.ListItemIcon>
                </UI.ListItem>


              )}
            </UI.List>
          )}
        </UI.Box>
      )}
      {session.groupFitnessActivities.length > 0 && (
        <>
          <UI.Typography>
            Choose how you get to the task
          </UI.Typography>
          <UI.List>


            {session.groupFitnessActivities.map((gfa) => {
              const isSignedUp = gfa.signups.some((s) => s.id === runner?.id);
              const hasPunctuation = /[.!?]$/.test(gfa.description?.trim() || "");
              const cleanDescription = gfa.description
                ? gfa.description.trim() + (hasPunctuation ? "" : ".")
                : "";

              const rawPhrases = [
                gfa.bagDrop && "there is a bag drop",
                gfa.toilets && "toilets are available",
                gfa.accessibleToilets && "accessible toilets are available",
                gfa.return && "you’ll be brought back to the start afterwards"
              ].filter(Boolean) as string[];

              const capitalise = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

              const joinedPhrases =
                rawPhrases.length === 0
                  ? ""
                  : rawPhrases.length === 1
                  ? capitalise(rawPhrases[0]) + "."
                  : capitalise(
                      rawPhrases.slice(0, -1).join(", ") + ", and " + rawPhrases.slice(-1)
                    ) + ".";

              return (
                <UI.ListItem key={gfa.id} disableGutters  alignItems="flex-start">

                  <UI.ListItemIcon sx={{ mr: 2 }}>
                    <UI.Avatar style={{ fontSize: "28px" }}>
                    {activityIcons[gfa.type as keyof typeof activityIcons]}

                    </UI.Avatar>
                  </UI.ListItemIcon>

                  <UI.ListItemText

                    primary={
                      <>
                      < UI.Box sx={{ display: "flex"}}>
                            {gfa.signups.map((runner) => (
                            <UI.Tooltip title={runner.name} key={runner.id}>
                                <UI.Avatar
                                src={runner.avatarUrl}
                                alt={runner.name}
                                sx={{ width: 24, height: 24, mr: 0.5 }}
                                />
                            </UI.Tooltip>
                            ))}
                        </UI.Box>
                        <UI.Link
                        sx={{ color: "black.main", cursor: "pointer" }}
                        href={u.geo.directionsLink?.(gfa.address!, session.address!)}
                        >
                          {gfa.type} {gfa.distance}km from{" "}
                         {gfa.address.line1}, {gfa.address.postcode.split(" ")[0]} at {u.time.format(gfa.startedAt, "h:mma")}
                        </UI.Link>
                        {(gfa.ledBy.id === runner?.id ||
                          u.roles.hasRoleOrAdmin(runner, "TRAINER")) && (
                          <>
                            <UI.IconButton
                              size="small"
                              href={u.links.editGroupFitnessActivity(
                                session,
                                gfa
                              )}
                              sx={{ ml: 1 }}
                            >
                              <Icons.Edit />
                            </UI.IconButton>
                            <UI.IconButton
                              size="small"
                              onClick={deleteGroupFitnessActivity({
                                session,
                                gfa,
                              })}
                            >
                              <Icons.Delete />
                            </UI.IconButton>
                          </>
                        )}

                      </>
                    }


                    secondary={
                      <>
                      Join the {gfa.type.toLocaleLowerCase()}  with <UI.RunnerLinks runners={gfa.signups} />{" "}
                    <UI.Typography variant="body2">
                        {cleanDescription}{" "}
                        {joinedPhrases && (
                        <>
                            {joinedPhrases}
                            {gfa.return && (
                            <UI.Tooltip title="Returning back to the start location">
                                <NP.Return
                                css={{
                                    fill: "#E11018",
                                    width: 25,
                                    height: 25,
                                    verticalAlign: "middle"
                                }}
                                />
                            </UI.Tooltip>
                        )}
                        </>
                        )}
                    </UI.Typography>

                      </>

                    }

                  />



                {!isSignedUp ? (
                <UI.ListItemIcon sx={{ ml: 2 }}>
                    <UI.Button onClick={() => showSignupModal(gfa)}>
                    Join {gfa.type}
                    </UI.Button>
                </UI.ListItemIcon>
                ) : (
                <UI.ListItemIcon sx={{ ml: 2 }}>
                    <UI.Button disabled variant="outlined" color="neutral">
                    Signed up
                    </UI.Button>
                </UI.ListItemIcon>
                )}


{/*
                  <UI.ListItemIcon sx={{ ml: 2 }}>
                    <UI.Button onClick={() => showSignupModal(gfa)}>
                        Join {gfa.type}
                    </UI.Button>
                  </UI.ListItemIcon> */}

                </UI.ListItem>
              );
            })}

    {session.type !== "group run" && (
              <UI.ListItem disableGutters>
                <UI.ListItemIcon sx={{ mr: 2 }}>
                  <UI.Avatar style={{ fontSize: "28px" }}>
                    <Icons.Person />
                  </UI.Avatar>
                </UI.ListItemIcon>
                <UI.ListItemText
                  primary={
                    <UI.Link
                      sx={{ color: "black.main", cursor: "pointer" }}
                      onClick={() => showSignupModal()}
                    >
                      Make your own way there
                    </UI.Link>
                  }
                  secondary="We encourage you to walk, run, or cycle"
                />
                <UI.ListItemIcon sx={{ ml: 2 }}>
                  <UI.Button color="secondary" onClick={() => showSignupModal()}>
                    Meet at task
                  </UI.Button>
                </UI.ListItemIcon>
              </UI.ListItem>
            )}

            {u.roles.hasSessionAdmin(runner, session) && (
              <UI.ListItem disableGutters>
                <UI.ListItemIcon sx={{ mr: 2 }}>
                  <UI.Avatar style={{ fontSize: "28px" }}>
                    <Icons.Person />
                  </UI.Avatar>
                </UI.ListItemIcon>
                <UI.ListItemText
                  primary={
                    <UI.Link
                      sx={{ color: "black.main" }}
                      href={u.links.newGroupFitnessActivity(session)}
                    >
                      Invite others to run, walk, or cycle with you
                    </UI.Link>
                  }
                  secondary="Let people know where and when to meet you"
                />
                <UI.ListItemIcon sx={{ ml: 2 }}>
                  <UI.IconButton href={u.links.newGroupFitnessActivity(session)}>
                    <Icons.ArrowForward />
                  </UI.IconButton>
                </UI.ListItemIcon>
              </UI.ListItem>
            )}
          </UI.List>
        </>
      )}
    </UI.Box>
  );
};

export default GettingThere;
