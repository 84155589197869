/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as NP from "@goodgym/noun-project";
import * as u from "@goodgym/util";
import _ from "lodash";

export type GroupFitnessActivitySelectProps = {
  onClose: React.MouseEventHandler;
  next: (gfaId: T.GroupFitnessActivityFragment["id"] | "NONE") => any;
  session: T.SessionCardFragment & {
    groupFitnessActivities: (T.GroupFitnessActivityFragment & {
      signups: T.RunnerFragment[];
      ledBy: T.RunnerFragment;
    })[];
  };
};

const GroupFitnessActivitySelect: React.FC<
  React.PropsWithChildren<GroupFitnessActivitySelectProps>
> = ({ session, next, onClose, ...props }) => {
  const noGfaId = _.uniqueId();
  const [selected, setSelected] = React.useState<Optional<string>>(null);

  const onClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    if (selected) {
      next(selected);
    }
  };
    const activityIcons = {
      Run: <Icons.DirectionsRun fontSize="small" />,
      Walk: <Icons.DirectionsWalk fontSize="small" />,
      Cycle: <Icons.DirectionsBike fontSize="small" />
    };

  return (
    <>
      <UI.DialogTitle>
        Getting there
        <UI.CloseButton onClick={onClose} />
      </UI.DialogTitle>
      <UI.DialogContent sx={{ mb: 0, pb: 0 }}>
        <UI.Typography>
          You're signing up to <strong>{session.title}</strong>. There are a few
          options for getting to the task:
        </UI.Typography>
        <UI.List>

          {session.groupFitnessActivities.map((gfa) => {
            const id = _.uniqueId();
            const hasPunctuation = /[.!?]$/.test(gfa.description?.trim() || "");
            const cleanDescription = gfa.description
              ? gfa.description.trim() + (hasPunctuation ? "" : ".")
              : "";

            const rawPhrases = [
              gfa.bagDrop && "there is a bag drop",
              gfa.toilets && "toilets are available",
              gfa.accessibleToilets && "accessible toilets are available",
              gfa.return && "you’ll be brought back to the start afterwards"
            ].filter(Boolean) as string[];

            const capitalise = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

            const joinedPhrases =
              rawPhrases.length === 0
                ? ""
                : rawPhrases.length === 1
                ? capitalise(rawPhrases[0]) + "."
                : capitalise(
                    rawPhrases.slice(0, -1).join(", ") + ", and " + rawPhrases.slice(-1)
                  ) + ".";

            return (


                <React.Fragment key={gfa.id}>


                <UI.ListItem
                  alignItems="center"
                  disableGutters
                  component="label"
                  htmlFor={id}
                  sx={{ cursor: "pointer",pt: 0 }}
                >
                  <UI.ListItemIcon sx={{ mr: 2, mt: 1 }}>
                    <UI.Avatar style={{ fontSize: "28px" }}>
                    {activityIcons[gfa.type as keyof typeof activityIcons]}
                    </UI.Avatar>
                  </UI.ListItemIcon>
                  <UI.ListItemText
                  primary={
                    <>
                      {gfa.type} {gfa.distance}km from {gfa.address.line1}, {gfa.address.postcode.split(" ")[0]} at {u.time.format(gfa.startedAt, "h:mma")}
                    </>
                  }
                secondary={
                      <>
                        Join the {gfa.type.toLocaleLowerCase()} with <UI.RunnerLinks runners={gfa.signups} />{" "}
                        <UI.Typography variant="body2">
                          {cleanDescription}{" "}
                                                {joinedPhrases && (
                                                <>
                                                    {joinedPhrases}
                                                    {gfa.return && (
                                                    <UI.Tooltip title="Returning back to the start location">
                                                        <NP.Return
                                                        css={{
                                                            fill: "#E11018",
                                                            width: 25,
                                                            height: 25,
                                                            verticalAlign: "middle"
                                                        }}
                                                        />
                                                    </UI.Tooltip>
                                                )}
                                                </>
                                                )}
                        <UI.Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap", // optional: lets it wrap on small screens
                            gap: 0.5, // tight spacing between avatars (theme spacing unit)
                        }}
                        >
                        {gfa.signups.map((runner) => (
                            <UI.Tooltip title={runner.name} key={runner.id}>
                            <UI.Avatar
                                src={runner.avatarUrl}
                                alt={runner.name}
                                sx={{ width: 20, height: 20 }}
                            />
                            </UI.Tooltip>
                        ))}
                        </UI.Box>

                        </UI.Typography>
                      </>
                    }
                  />

                  <UI.Radio
                    id={id}
                    checked={selected === gfa.id}
                    onChange={() => setSelected(gfa.id)}
                    sx={{ ml: 2 }}
                  />
                </UI.ListItem>
              </React.Fragment>

            );
          })}
           {session.type !== "group run" && (
            <UI.ListItem
              disableGutters
              component="label"
              htmlFor={noGfaId}
              sx={{ cursor: "pointer" }}
            >
              <UI.ListItemIcon sx={{ mr: 2 }}>
                <UI.Avatar style={{ fontSize: "28px" }}>
                  <Icons.Person />
                </UI.Avatar>
              </UI.ListItemIcon>
              <UI.ListItemText
                primary="Make your own way there"
                secondary="We encourage you to walk, run, or cycle"
              />
              <UI.Radio
                id={noGfaId}
                checked={selected === "NONE"}
                onChange={() => setSelected("NONE")}
                sx={{ ml: 2 }}
              />
            </UI.ListItem>
          )}
        </UI.List>
      </UI.DialogContent>
      <UI.DialogActions sx={{ mt: 0, pt: 0, m: 2 }}>
        <UI.Button variant="text" color="neutral" fullWidth onClick={onClose}>
          Cancel
        </UI.Button>
        <UI.Button
          color="secondary"
          fullWidth
          disabled={selected === null}
          onClick={onClick}
        >
          Next
        </UI.Button>
      </UI.DialogActions>
    </>
  );
};

export default GroupFitnessActivitySelect;
